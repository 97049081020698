import { REPORT_MODE } from "./constants";

export default {
  filteringPanel: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Display mode",
        tooltip: "Display mode",
        name: "mode",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [
          { id: REPORT_MODE.DISTRIBUTORS, label: "Distributors" },
          { id: REPORT_MODE.MARKETS, label: "Markets" }
        ],
        selected: {}
      },
      {
        type: "select",
        dataType: "territory",
        title: "Territory",
        tooltip: "Distributor billing territory",
        name: "territory",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Market",
        tooltip: "Sales market",
        name: "market",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Distributors",
        tooltip: "Distributors",
        name: "distributor",
        trackby: "id",
        label: "label",
        multiple: true,
        options: [],
        selected: {}
      },

      {
        type: "select",
        dataType: "string",
        title: "Metrics",
        tooltip: "Reports metrics",
        name: "metric",
        trackby: "id",
        label: "label",
        multiple: false,
        allowEmpty: false,
        options: [
          {
            id: "sold",
            label: "$ Sold"
          },
          {
            id: "bottles",
            label: "Bottles"
          },
          {
            id: "alc_amount",
            label: "Amount of alcohol"
          }
        ],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      }
    ]
  },
  filteringPanel1: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Market",
        tooltip: "Sales market",
        name: "market",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      }
    ]
  },
  filteringPanel2: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Market",
        tooltip: "Sales market",
        name: "market",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      }
    ]
  },

  filteringPanel5: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Market",
        tooltip: "Sales market",
        name: "market",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      }
    ]
  },

  filteringPanel6: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Market",
        tooltip: "Sales market",
        name: "market",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period2",
        options: []
      }
    ]
  },
  filteringPanel7: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Market",
        tooltip: "Sales market",
        name: "market",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period2",
        options: []
      }
    ]
  }
};
