<template>
  <b-modal
    ref="modal"
    centered
    scrollable
    hide-footer
    size="xl"
    :title="`${marketName} market overview`"
    class="p-0"
    @shown="onModalShown"
  >
    <b-container
      fluid
      class="ma-0 pa-0"
      style="width:85vw;height: 75vh;overflow:auto;"
    >
      <MarketOverview ref="marketOverview" :autoload="false" />
    </b-container>
    <hr />

    <form-submission-actions
      :mode="$constants.FORM_MODE.READONLY"
      :hide-regular-buttons="true"
      @custom-click="
        name => {
          this[name]();
        }
      "
      :custom-buttons="[
        {
          text: 'Close',
          icon: 'times',
          loading: false,
          visible: true,
          disabled: false,
          method: 'onClose',
          variant: 'outline-dark'
        }
      ]"
    />
  </b-modal>
</template>

<script>
import FormSubmissionActions from "@/components/FormSubmissionActions";
//import MarketOverview from "@/views/Distribution/Market/Overview.vue";
import MarketOverview from "@/views/Distribution/Market/MarketOverview/index.js";
export default {
  name: "MarketModal",
  components: {
    FormSubmissionActions,
    MarketOverview
  },
  data: function() {
    return {
      countryName: "",
      marketName: ""
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    onModalShown() {
      this.$refs.marketOverview.setMarket({
        country: this.countryName,
        state: this.marketName,
        period: this.period
      });
    },
    show(payload) {
      this.countryName = payload.countryName;
      this.marketName = payload.marketName;
      this.period = payload.period;
      this.$refs["modal"].show();
    },
    onClose() {
      this.$refs["modal"].hide();
    }
  }
};
</script>

<style scoped>
::v-deep .modal-xl {
  max-width: 1600px !important;
}
</style>
