var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    staticClass: "p-0",
    attrs: {
      "centered": "",
      "scrollable": "",
      "hide-footer": "",
      "size": "xl",
      "title": "".concat(_vm.marketName, " market overview")
    },
    on: {
      "shown": _vm.onModalShown
    }
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "width": "85vw",
      "height": "75vh",
      "overflow": "auto"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('MarketOverview', {
    ref: "marketOverview",
    attrs: {
      "autoload": false
    }
  })], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.$constants.FORM_MODE.READONLY,
      "hide-regular-buttons": true,
      "custom-buttons": [{
        text: 'Close',
        icon: 'times',
        loading: false,
        visible: true,
        disabled: false,
        method: 'onClose',
        variant: 'outline-dark'
      }]
    },
    on: {
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }