import { isMobile } from "mobile-device-detect";

export default {
  chart1: {
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      chartArea: {
        width: "80%",
        height: "80%"
      },
      sizeAxis: {
        minValue: -100,
        maxValue: 100
      },
      //region: '150',
      //resolution: 'countries',
      resolution: "provinces",
      //displayMode: 'regions',
      magnifyingGlass: {
        enable: true,
        zoomFactor: 50
      },
      colorAxis: {
        colors: ["red", "yellow", "green"],
        minValue: -100,
        maxValue: 100
      }
    }
  },
  chart2: {
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      chartArea: {
        width: "65%",
        height: "80%"
      },
      legend: {
        position: "none"
      },
      vAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    }
  },
  chart5: {
    googleRef: undefined,
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    chartData: null,
    chartOptions: {
      //width: 1000,
      height: 400,

      chartArea: {
        width: "70%",
        height: "60%"
      },
      vAxis: {
        viewWindow: {
          // max: 200,
        }
      },
      legend: {
        position: "bottom"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        }
      },
      thickness: 2,
      displayZoomButtons: false
    }
  },
  chart6: {
    isDefaultMode: true,
    isColumnType: true,
    groupByYearsView: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    chartData: null,
    type: "ColumnChart",
    chartOptions: {
      height: 400,
      curveType: "function",
      chartArea: {
        width: isMobile ? "65%" : "80%",
        height: "80%"
      },
      vAxis: {
        viewWindow: {
          // max: 200,
        }
      },
      explorer: {
        actions: ["dragToZoom", "rightClickToReset"],
        keepInBounds: true,
        maxZoomIn: 4.0
      },
      legend: {
        position: "top"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        },
        format: "#"
      }
    }
  },
  chart7: {
    type: "ColumnChart",
    isBarChart: true,
    pagination: {
      value: 1,
      total: 0
    },
    rawData: [],
    emptyStates: [],
    loading: false,
    chartData: null,
    chartOptions: {
      height: 800,
      chartArea: {
        width: isMobile ? "65%" : "80%",
        height: "70%"
      },

      vAxis: {
        viewWindow: {
          max: 1,
          min: 0
        }
      },

      explorer: {
        actions: ["dragToZoom", "rightClickToReset"],
        keepInBounds: true,
        maxZoomIn: 5.0
      },

      legend: {
        position: "bottom"
      },
      hAxis: {
        textStyle: {
          fontSize: 12
        },
        viewWindow: {
          max: 1,
          min: 0
        }
      }
    }
  }
};
