<template>
  <div class="animated fadeIn">
    <fab
      position="bottom-right"
      position-type="fixed"
      bg-color="#2f353a"
      icon-size="small"
      main-icon="menu"
      :fixed-tooltip="true"
      :actions="fabActions"
      @bookmark1="scrollToBookmark('bookmark1')"
      @bookmark2="scrollToBookmark('bookmark2')"
      @bookmark3="scrollToBookmark('bookmark3')"
      @bookmark4="scrollToBookmark('bookmark4')"
      @bookmark5="scrollToBookmark('bookmark5')"
    ></fab>

    <b-card v-show="true">
      <h2>National Overview - Warehouse</h2>
        <br>
        <h4>Main Period</h4>
        <br>
        <span>Select your main data. All charts compare to this data.</span>
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            dataset-name="warehouse/national-overview-top-bar"
            :show-labels="true"
            :compact="true"
            :districts="filteringPanel.districts"
            :filters="filteringPanel.filters"
            @change="onFilteringPanelChange"
            @search="getData(0)"
            @reset="onFilteringPanelReset"
            :loaded="filteringPanel.loaded"
          />
        </b-col>
      </b-row>
      <hr />
      <br>
      <br>
      <br>
      <br>
      <hr />
      <b-row>
        <b-col lg="12" ref="bookmark1">
          <b-popover target="chart1-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
          <g-chart-custom
            id="chart1"
            :default-mode="charts.chart1.isDefaultMode"
            ref="chart1"
            title="Sales Development (+/-) CHART 1"
            :subtitle="charts.chart1.subtitle"
            :loading="charts.chart1.loading"
            type="GeoChart"
            :settings="{
              packages: ['geochart'],
              mapsApiKey: 'AIzaSyDPm_pLRVSsqrEqye4f4zJD0ZR-cE9Cbdk'
            }"
            :data="charts.chart1.chartData"
            :options="charts.chart1.chartOptions"
            @chart-element-selected="stateChartClick($event, 'chart1')"
          >
            <template #legend>
              <i id="chart1-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <filtering-panel
                ref="filteringPanel1"
                mode="server"
                dataset-name="warehouse/national-overview-chart-1"
                :show-labels="true"
                :compact="true"
                :hide-reset-button="true"
                :filters="filteringPanel1.filters"
                @search="getData(1)"
                :loaded="filteringPanel1.loaded"
              />
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col />
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart1.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart1.isDefaultMode = !charts.chart1.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="list" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart1.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart1.isDefaultMode = !charts.chart1.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="chart-pie" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart1Table.name"
                :loading="chart1Table.isLoading"
                :data="chart1Table.dataSet"
                :options="chart1Table.options"
                @mounted="onChart1TableMount"
              />
            </template>
          </g-chart-custom>
          <hr />
          <br>
          <br>
          <br>
          <br>
          <hr />
          <b-row v-if="charts.chart1.emptyStates.length > 0">
            <b-col>
              <p />
              <h5>States without data for time period:</h5>
              {{ charts.chart1.emptyStates.join(", ") }}
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12" ref="bookmark2">
          <b-popover target="chart2-help" triggers="hover" placement="right">
            <span v-html="popoverTemplate" />
          </b-popover>
          <g-chart-custom
            ref="chart2"
            :default-mode="charts.chart2.isDefaultMode"
            title="Sales Development (+/-) CHART 2"
            :subtitle="charts.chart2.subtitle"
            :type="$isMobile ? 'ColumnChart' : 'BarChart'"
            :loading="charts.chart2.loading"
            :data="charts.chart2.chartData"
            :options="charts.chart2.chartOptions"
            @chart-element-selected="stateChartClick($event, 'chart2')"
          >
            <template #legend>
              <i id="chart2-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <filtering-panel
                ref="filteringPanel2"
                mode="server"
                dataset-name="warehouse/national-overview-chart-2"
                :show-labels="true"
                :compact="true"
                :hide-reset-button="true"
                :filters="filteringPanel2.filters"
                @search="getData(2)"
                :loaded="filteringPanel2.loaded"
              />
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col />
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart2.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart2.isDefaultMode = !charts.chart2.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="list" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart2.isDefaultMode"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart2.isDefaultMode = !charts.chart2.isDefaultMode
                    "
                  >
                    <font-awesome-icon icon="chart-pie" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #table>
              <table-custom
                :name="chart2Table.name"
                :loading="chart2Table.isLoading"
                :data="chart2Table.dataSet"
                :options="chart2Table.options"
                @mounted="onChart2TableMount"
              />
            </template>
          </g-chart-custom>
          <b-row v-if="charts.chart2.emptyStates.length > 0">
            <b-col>
              <p />
              <h5>States without data for time period:</h5>
              {{ charts.chart2.emptyStates.join(", ") }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr />
      <br>
      <br>
      <br>
      <br>
      <hr />
      <b-row>
        <b-col lg="12" ref="bookmark3">
          <div ref="chart5container">
            <g-chart-custom
              id="chart5"
              ref="chart5"
              :title="`Total sales (${selectedMetric().label})`"
              subtitle="Comparison of sales by periods"
              :show-tabs="true"
              :default-mode="charts.chart5.isDefaultMode"
              @ready="onTotalSalesReady"
              :loading="charts.chart5.loading"
              type="AnnotationChart"
              :settings="{ packages: ['annotationchart'] }"
              :data="charts.chart5.chartData"
              :options="charts.chart5.chartOptions"
            >
              <template #legend>
                <i id="chart5-help" class="fa fa-question-circle" />
              </template>
              <template #filter>
                <filtering-panel
                  ref="filteringPanel5"
                  mode="server"
                  dataset-name="warehouse/national-overview-chart-5"
                  :show-labels="true"
                  :compact="true"
                  :hide-reset-button="true"
                  :filters="filteringPanel5.filters"
                  @search="getData(5)"
                  :loaded="filteringPanel5.loaded"
                />
              </template>
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col v-show="charts.chart5.isDefaultMode">
                    <b-button-group
                      v-show="charts.chart5.isDefaultMode"
                      class="mb-2"
                    >
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(3)"
                      >
                        3 months
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(6)"
                      >
                        6 months
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(12)"
                      >
                        1 year
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(24)"
                      >
                        2 years
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="onChart5ZoomClick(60)"
                      >
                        5 years
                      </b-button>
                    </b-button-group>
                  </b-col>
                  <b-col v-show="!charts.chart5.isDefaultMode">
                    <b-form-checkbox
                      name="checkboxGroupByYears"
                      inline
                      switch
                      v-model="chart5Table.groupByYearsView"
                      @input="updateChart5Table"
                    >
                      Group by years
                    </b-form-checkbox>
                    <b-form-checkbox
                      name="checkboxYTD"
                      inline
                      v-show="chart5Table.groupByYearsView"
                      v-model="chart5Table.YTD"
                      @input="updateChart5Table"
                    >
                      Limit to YTD
                    </b-form-checkbox>
                  </b-col>

                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart5.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart5.isDefaultMode = !charts.chart5
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart5.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart5.isDefaultMode = !charts.chart5
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                <table-custom
                  :name="chart5Table.name"
                  :loading="chart5Table.isLoading"
                  :data="chart5Table.dataSet"
                  :options="chart5Table.options"
                  @mounted="onChart5TableMount"
                />
              </template>
            </g-chart-custom>
          </div>
        </b-col>
      </b-row>
      <hr />
      <br>
      <br>
      <br>
      <br>
      <hr />
      <b-row>
        <b-col lg="12" ref="bookmark4">
          <g-chart-custom
            ref="chart6"
            :title="`Total sales comparison (${selectedMetric().label})`"
            subtitle="Comparison of sales by periods"
            :default-mode="charts.chart6.isDefaultMode"
            :type="charts.chart6.type"
            :loading="charts.chart6.loading"
            :data="charts.chart6.chartData"
            :options="charts.chart6.chartOptions"
          >
            <template #legend>
              <i id="chart6-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <b-container fluid>
                <b-row>
                  <b-col sm="12" md="12" lg="12">
                    <filtering-panel
                      ref="filteringPanel6"
                      mode="server"
                      dataset-name="national-overview-chart-6"
                      :show-labels="true"
                      :compact="true"
                      :hide-reset-button="true"
                      :filters="filteringPanel6.filters"
                      @search="getData(6)"
                      :loaded="filteringPanel6.loaded"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col>
                  <b-form-checkbox
                    name="checkboxGroupByYears2"
                    inline
                    switch
                    v-model="charts.chart6.groupByYearsView"
                    @input="updateChart6"
                  >
                    Group by years
                  </b-form-checkbox>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart6.isColumnType"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart6.isColumnType = !charts.chart6.isColumnType;
                      charts.chart6.type = 'LineChart';
                    "
                  >
                    <font-awesome-icon icon="chart-line" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart6.isColumnType"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart6.isColumnType = !charts.chart6.isColumnType;
                      charts.chart6.type = 'ColumnChart';
                    "
                  >
                    <font-awesome-icon icon="chart-bar" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>
      <hr />
      <br>
      <br>
      <br>
      <br>
      <hr />
      <b-row>
        <b-col ref="bookmark5">
          <b-popover target="chart7-help" triggers="hover" placement="right">
            <span v-html="popoverTemplateAllMetrics" />
          </b-popover>
          <g-chart-custom
            ref="chart7"
            :title="`Distributors sales (${selectedMetric().label})`"
            subtitle="Comparison of sales by periods"
            :type="charts.chart7.type"
            :loading="charts.chart7.loading"
            :data="charts.chart7.chartData"
            :options="charts.chart7.chartOptions"
          >
            <template #legend>
              <i id="chart7-help" class="fa fa-question-circle" />
            </template>
            <template #filter>
              <b-container fluid>
                <b-row>
                  <b-col sm="12" md="12" lg="12">
                    <filtering-panel
                      ref="filteringPanel7"
                      mode="server"
                      dataset-name="warehouse/national-overview-chart-7"
                      :show-labels="true"
                      :compact="true"
                      :hide-reset-button="true"
                      :filters="filteringPanel7.filters"
                      @search="getData(7)"
                      :loaded="filteringPanel7.loaded"
                    />
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template #chartTopBar>
              <b-row align-h="between">
                <b-col lg="2" sm="12" md="12">
                  <b-pagination
                    v-model="charts.chart7.pagination.value"
                    pills
                    :hide-ellipsis="true"
                    :total-rows="charts.chart7.pagination.total"
                    :per-page="10"
                    @input="updateChart7"
                  />
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-show="charts.chart7.isBarChart"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart7.isBarChart = !charts.chart7.isBarChart;
                      charts.chart7.type = 'BarChart';
                      updateChart7();
                    "
                  >
                    <font-awesome-icon icon="chart-line" />
                  </b-button>
                  <b-button
                    v-show="!charts.chart7.isBarChart"
                    class="mb-2"
                    variant="outline-primary"
                    @click="
                      charts.chart7.isBarChart = !charts.chart7.isBarChart;
                      charts.chart7.type = 'ColumnChart';
                      updateChart7();
                    "
                  >
                    <font-awesome-icon icon="chart-bar" />
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </g-chart-custom>
        </b-col>
      </b-row>

      <market-modal ref="marketModal"></market-modal>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import FilteringPanel from "@/components/FilteringPanel";
import GChartCustom from "@/components/GChartCustom";
import TableCustom from "@/components/TableCustom";

import fp from "./filters";
import charts from "./charts";
import tables from "./tables";

import { REPORT_MODE } from "./constants";
//import fab from "vue-fab";
import fab from "@/components/FloatingActionButton";

import MarketModal from "./MarketModal";

export default {
  name: "Overview",
  props: {
    accountFilter: {
      type: String,
      default: undefined
    },
    marketFilter: {
      type: String,
      default: undefined
    }
  },
  components: {
    FilteringPanel,
    GChartCustom,
    TableCustom,
    MarketModal,
    fab
  },
  data: function() {
    return {
      fabActions: [
        {
          name: "bookmark1",
          icon: "insights",
          tooltip: "Sales growth geo chart"
        },
        {
          name: "bookmark2",
          icon: "insights",
          tooltip: "Sales growth bar chart"
        },

        {
          name: "bookmark3",
          icon: "insights",
          tooltip: "Total sales"
        },
        {
          name: "bookmark4",
          icon: "insights",
          tooltip: "Total sales comparison"
        },
        {
          name: "bookmark5",
          icon: "insights",
          tooltip: "Distributor sales"
        }
      ],
      isLoading: false,
      activeTab: 0,
      filteringPanel: fp.filteringPanel,
      filteringPanel1: fp.filteringPanel1,
      filteringPanel2: fp.filteringPanel2,
      filteringPanel5: fp.filteringPanel5,
      filteringPanel6: fp.filteringPanel6,
      filteringPanel7: fp.filteringPanel7,
      labels: {
        topPeriod: "",
        period1: "",
        period2: ""
      },
      popoverTemplate:
        "<ul> <li>Selection period: The user selected date range</li> </ul>",
      popoverTemplateAllMetrics:
        "<ul> <li>Selection period: The user selected date range</li><li>Reference period: A time period of equal length immediately prior to the selected date range</li> <li>Same period one year prior: The selected date range one year prior to user input</li> </ul>",
      charts: charts,
      chart1Table: tables.chart1Table,
      chart2Table: tables.chart2Table,
      chart5Table: tables.chart5Table,
      districts: []
    };
  },
  computed: {},
  created() {
    //window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    //window.removeEventListener("scroll", this.handleScroll);
  },
  async mounted() {
    if (!this.$refs.filteringPanel.selected.metric)
      this.$refs.filteringPanel.selected.metric = this.filteringPanel.filters.find(
        f => f.name === "metric"
      ).options[0];

    await this.loadDictionaries();

    this.updateFilters();

    this.updateDateRanges();

    this.getData(0);

    this.$refs.chart5container.style.width = this.$isMobile ? "90%" : "80%";
    //var container = document.getElementById("chart_div").firstChild.firstChild;
    // container.style.width = "100%";
  },

  methods: {
    handleScroll() {
      // Any code to be executed when the window is scrolled
    },
    stateChartClick(e, chartName) {
      if (!e.selection) return;

      const selectedItem = e.data[e.selection.row + 1][0];

      const row = this.charts[chartName].rawData.find(
        i => i.market === selectedItem
      );
      const marketCountry = row.market_country;

      let payload = {
        countryName: marketCountry,
        marketName: selectedItem === marketCountry ? "" : selectedItem,
        period: this.$refs.filteringPanel.selected.period
      };

      this.$refs.marketModal.show(payload);
    },
    scrollToBookmark(bookmarkName) {
      var element = this.$refs[bookmarkName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    reportMode() {
      return this.$refs.filteringPanel.selected.mode
        ? this.$refs.filteringPanel.selected.mode.id
        : "markets";
    },

    selectedMetric() {
      if (
        !this.$refs.filteringPanel ||
        !this.$refs.filteringPanel.selected.metric
      )
        return this.filteringPanel.filters.find(f => f.name === "metric")
          .options[0];

      return this.$refs.filteringPanel.selected.metric;
    },
    onTotalSalesReady(payload) {
      this.charts.chart5.googleRef = payload.chart;
    },
    onChart5ZoomClick(months) {
      this.charts.chart5.googleRef.setVisibleChartRange(
        moment()
          .subtract(months, "month")
          .toDate(),
        moment().toDate()
      );
    },

    onChart1TableMount() {
      let self = this;

      let title = "";
      let column = "";

      if (this.selectedMetric().id === "sold") {
        title = "$ Sold";
        column = "sales";
        //      this.chart1Table = tables.chart1Table
      }

      if (this.selectedMetric().id === "bottles") {
        title = "Bottles";
        column = "bottles";
        this.chart1Table = tables.chart1TableBottles;
      }

      if (this.selectedMetric().id === "alc_amount") {
        title = "Amount";
        column = "alc_amount";
        this.chart1Table = tables.chart1TableAlcAmount;
      }

      if (this.reportMode() === REPORT_MODE.DISTRIBUTORS)
        this.chart1Table.options.columns = [
          "Distributor",
          "Market",
          `${title} [${this.labels.topPeriod}]`,
          `${title} [${this.labels.period1}]`,
          "$ Difference",
          "% Change"
        ];
      if (this.reportMode() === REPORT_MODE.MARKETS)
        this.chart1Table.options.columns = [
          "Market",
          `${title} [${this.labels.topPeriod}]`,
          `${title} [${this.labels.period1}]`,
          "$ Difference",
          "% Change"
        ];

      this.chart1Table.dataSet = this.charts.chart1.rawData.map(function(i) {
        let obj = {};

        if (self.reportMode() === REPORT_MODE.DISTRIBUTORS)
          obj["Distributor"] = i.accountname;

        obj["Market"] = i.market;
        obj[`${title} [${self.labels.topPeriod}]`] = i[`${column}1`];
        obj[`${title} [${self.labels.period1}]`] = i[`${column}2`];
        obj["$ Difference"] = i[`${column}_delta2`];
        obj["% Change"] = parseFloat(i[`${column}_delta_percent2`] || 0) * 100;

        return obj;
      });
    },
    onChart2TableMount() {
      let self = this;

      let title = "";
      let column = "";

      if (this.selectedMetric().id === "sold") {
        title = "$ Sold";
        column = "sales";
        this.chart2Table = tables.chart2Table;
      }

      if (this.selectedMetric().id === "bottles") {
        title = "Bottles";
        column = "bottles";
        this.chart2Table = tables.chart2TableBottles;
      }

      if (this.selectedMetric().id === "alc_amount") {
        title = "Amount";
        column = "alc_amount";
        this.chart2Table = tables.chart2TableAlcAmount;
      }

      if (this.reportMode() === REPORT_MODE.DISTRIBUTORS)
        this.chart2Table.options.columns = [
          "Distributor",
          "Market",
          `${title} [${this.labels.topPeriod}]`,
          `${title} [${this.labels.period1}]`,
          "$ Difference",
          "% Change"
        ];
      if (this.reportMode() === REPORT_MODE.MARKETS)
        this.chart2Table.options.columns = [
          "Market",
          `${title} [${this.labels.topPeriod}]`,
          `${title} [${this.labels.period1}]`,
          "$ Difference",
          "% Change"
        ];

      this.chart2Table.dataSet = this.charts.chart2.rawData.map(function(i) {
        let obj = {};

        if (self.reportMode() === REPORT_MODE.DISTRIBUTORS)
          obj["Distributor"] = i.accountname;

        obj["Market"] = i.market;
        obj[`${title} [${self.labels.topPeriod}]`] = i[`${column}1`];
        obj[`${title} [${self.labels.period1}]`] = i[`${column}2`];
        obj["$ Difference"] = i[`${column}_delta2`];
        obj["% Change"] = parseFloat(i[`${column}_delta_percent2`] || 0) * 100;

        return obj;
      });
    },

    onChart5TableMount() {
      if (this.selectedMetric().id === "sold") {
        this.chart5Table = tables.chart5Table;

        this.chart5Table.dataSet = this.charts.chart5.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          "$ Sold": i.sales
        }));
      }
      if (this.selectedMetric().id === "bottles") {
        this.chart5Table = tables.chart5TableBottles;

        this.chart5Table.dataSet = this.charts.chart5.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          Bottles: i.bottles
        }));
      }
      if (this.selectedMetric().id === "alc_amount") {
        this.chart5Table = tables.chart5TableAlcAmount;

        this.chart5Table.dataSet = this.charts.chart5.rawData.map(i => ({
          Year: i.yy,
          Month: i.mm,
          Amount: i.alc_amount
        }));
      }
    },
    updateChart5Table() {
      let self = this;

      let columnName = "",
        columnTitle = "";

      if (this.selectedMetric().id === "sold") {
        columnTitle = "$ Sold";
        columnName = "sales";
      }
      if (this.selectedMetric().id === "bottles") {
        columnTitle = "Bottles";
        columnName = "bottles";
      }
      if (this.selectedMetric().id === "alc_amount") {
        columnTitle = "Amount";
        columnName = "alc_amount";
      }

      if (!this.chart5Table.groupByYearsView) {
        this.chart5Table.options.columns = ["Year", "Month", columnTitle];
        this.chart5Table.options.filterable = ["Year", "Month", columnTitle];

        this.chart5Table.dataSet = this.charts.chart5.rawData.map(i => {
          let o = {
            Year: i.yy,
            Month: i.mm
          };
          o[columnTitle] = i[columnName];

          return o;
        });
      } else {
        let currentMonth = parseInt(moment().format("M"));

        this.chart5Table.options.columns = ["Year", columnTitle];
        this.chart5Table.options.filterable = ["Year", columnTitle];

        let groupedData = [];

        this.charts.chart5.rawData
          .filter(
            d =>
              parseInt(moment(d.yymmdd).format("M")) <=
              (self.chart5Table.YTD
                ? currentMonth
                : moment(d.yymmdd).format("M"))
          )
          .forEach(t => {
            let yy = groupedData.find(g => g["Year"] === t.yy);

            if (!yy) {
              let i = {
                Year: t.yy
              };

              i[columnTitle] = parseFloat(t[columnName]);
              groupedData.push(i);
            } else {
              yy[columnTitle] += parseFloat(t[columnName]);
            }
          });

        this.chart5Table.dataSet = groupedData;
      }
    },
    onFilteringPanelReset() {
      this.$refs.filteringPanel.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel1.resetFilters({
        resetStorage: true
      });

      this.$refs.filteringPanel2.resetFilters({
        resetStorage: true
      });
      this.$refs.filteringPanel5.resetFilters({
        resetStorage: true
      });
      this.$refs.filteringPanel6.resetFilters({
        resetStorage: true
      });
      this.$refs.filteringPanel7.resetFilters({
        resetStorage: true
      });

      this.updateDateRanges();
    },
    onFilteringPanelChange(payload) {
      this.updateDateRanges(payload);
    },
    updateFilters() {
      let distributor = this.filteringPanel.filters
        .find(f => f.name === "distributor")
        .options.find(i => i.label === this.accountFilter);

      let market = this.filteringPanel.filters
        .find(f => f.name === "market")
        .options.find(i => i.label === this.marketFilter);

      if (market)
        this.$refs.filteringPanel.setValue("market", "select", market);

      if (distributor)
        this.$refs.filteringPanel.setValue("distributor", "select", [
          distributor
        ]);
    },
    updateDateRanges() {
      let filter = this.$refs.filteringPanel.selected;

      //if user values are not defined then set date range to 1 year prior period selected at the top
      //https://gitlab.com/koval-projects/koval-crm/-/issues/211#note_356827025

      let defaultPeriod = {
        startDate: moment(filter.period.startDate)
          .subtract(1, "year")
          .format("YYYY-MM-DD"),
        endDate: moment(filter.period.endDate)
          .subtract(1, "year")
          .format("YYYY-MM-DD")
      };

      let defaultPeriod2 = {
        startDate: moment(filter.period.startDate)
          .subtract(2, "year")
          .format("YYYY-MM-DD"),
        endDate: moment(filter.period.endDate)
          .subtract(2, "year")
          .format("YYYY-MM-DD")
      };

      //set default period if storage was empty

      this.$refs.filteringPanel1.filtersSaved2Storage ||
        this.$refs.filteringPanel1.$refs["period"][0].setValue(defaultPeriod, {
          triggerChanged: false
        });
      this.$refs.filteringPanel2.filtersSaved2Storage ||
        this.$refs.filteringPanel2.$refs["period"][0].setValue(defaultPeriod, {
          triggerChanged: false
        });

      this.$refs.filteringPanel6.filtersSaved2Storage ||
        this.$refs.filteringPanel6.$refs["period"][0].setValue(defaultPeriod, {
          triggerChanged: false
        });
      this.$refs.filteringPanel6.filtersSaved2Storage ||
        this.$refs.filteringPanel6.$refs["period2"][0].setValue(
          defaultPeriod2,
          {
            triggerChanged: false
          }
        );

      this.$refs.filteringPanel7.filtersSaved2Storage ||
        this.$refs.filteringPanel7.$refs["period"][0].setValue(defaultPeriod, {
          triggerChanged: false
        });
      this.$refs.filteringPanel7.filtersSaved2Storage ||
        this.$refs.filteringPanel7.$refs["period2"][0].setValue(
          defaultPeriod2,
          {
            triggerChanged: false
          }
        );
    },

    loadDictionaries() {
      let self = this;

      const territories = async () => {
        let response = await self.$api.get("dictionaries/territories");

        self.filteringPanel.filters.find(
          f => f.name === "territory"
        ).options = response.map(u => ({
          id: u.ID,
          label: u.Name
        }));
      };

      const distributors = async () => {
        let response = await self.$api.get(
          "dictionaries/accounts/distributors"
        );

        self.filteringPanel.filters.find(
          f => f.name === "distributor"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      const markets = async () => {
        let response = await self.$api.get("dictionaries/markets");

        let markets = response.map(u => ({ id: u.id, label: u.name }));

        self.filteringPanel.filters.find(
          f => f.name === "market"
        ).options = markets;

        self.filteringPanel.filters.find(
          f => f.name === "market"
        ).options = markets;
        self.filteringPanel1.filters.find(
          f => f.name === "market"
        ).options = markets;
        self.filteringPanel2.filters.find(
          f => f.name === "market"
        ).options = markets;
        self.filteringPanel5.filters.find(
          f => f.name === "market"
        ).options = markets;
        self.filteringPanel6.filters.find(
          f => f.name === "market"
        ).options = markets;
        self.filteringPanel7.filters.find(
          f => f.name === "market"
        ).options = markets;
      };

      return Promise.all([territories(), distributors(), markets()]).then(
        () => {
          self.filteringPanel.loaded = true;
          self.filteringPanel1.loaded = true;
          self.filteringPanel2.loaded = true;
          self.filteringPanel5.loaded = true;
          self.filteringPanel6.loaded = true;
          self.filteringPanel7.loaded = true;
        }
      );
      /*
            return new Promise(async function (fulfill) {


                fulfill(true)
            })
*/
    },

    async getData(_mode) {
      let self = this;

      this.charts.chart1.emptyStates = [];
      this.charts.chart2.emptyStates = [];

      let topFilter = this.$refs.filteringPanel.selected;

      let filter1 = this.$refs.filteringPanel1.selected;
      let filter2 = this.$refs.filteringPanel2.selected;
      let filter5 = this.$refs.filteringPanel5.selected;
      let filter6 = this.$refs.filteringPanel6.selected;
      let filter7 = this.$refs.filteringPanel7.selected;

      this.labels.topPeriod = `${moment(topFilter.period.startDate).format(
        "YYYY-MM-DD"
      )} - ${moment(topFilter.period.endDate).format("YYYY-MM-DD")}`;

      this.labels.period1 = `${moment(filter1.period.startDate).format(
        "YYYY-MM-DD"
      )} - ${moment(filter1.period.endDate).format("YYYY-MM-DD")}`;

      this.labels.period2 = `${moment(filter2.period.startDate).format(
        "YYYY-MM-DD"
      )} - ${moment(filter2.period.endDate).format("YYYY-MM-DD")}`;

      let filterRequest = Object.assign({}, topFilter);

      if (_mode === 0 || _mode === 1) {
        //debugger
        filterRequest.period2 = filter1.period;

        //overwrite top level filter if exists
        !filter1.market ||
          (filterRequest.market = Object.assign({}, filter1.market));
        !filter1.distributor ||
          (filterRequest.distributor = Object.assign({}, filter1.distributor));

        this.charts.chart1.loading = true;

        this.charts.chart1.subtitle = `Percent change (${
          this.selectedMetric().label
        }) in the period ${this.labels.topPeriod} compared to ${
          this.labels.period1
        }`;

        this.$api.data
          .requestReportData(
            "warehouse/national-overview/state-level-metrics",
            filterRequest
          )
          .then(response => {
            self.drawChart1(response, filterRequest);

            self.charts.chart1.emptyStates = self.getEmptyStates(response);
          });
      }

      if (_mode === 0 || _mode === 2) {
        filterRequest.period2 = filter2.period;

        //overwrite top level filter if exists
        !filter2.market ||
          (filterRequest.market = Object.assign({}, filter2.market));
        !filter2.distributor ||
          (filterRequest.distributor = Object.assign({}, filter2.distributor));

        this.charts.chart2.loading = true;

        this.charts.chart2.subtitle = `Percent change (${
          this.selectedMetric().label
        }) in the period ${this.labels.topPeriod} compared to ${
          this.labels.period2
        }`;

        this.$api.data
          .requestReportData(
            "warehouse/national-overview/state-level-metrics",
            filterRequest
          )
          .then(response => {
            self.drawChart2(response, filterRequest);

            self.charts.chart2.emptyStates = self.getEmptyStates(response);
          });
      }

      if (_mode === 0 || _mode === 5) {
        //filter.state = filter5.state
        //filter.category = filter5.category

        //overwrite top level filter if exists
        !filter5.market ||
          (filterRequest.market = Object.assign({}, filter5.market));
        !filter5.distributor ||
          (filterRequest.distributor = Object.assign({}, filter5.distributor));

        this.charts.chart5.loading = true;

        this.$api.data
          .requestReportData(
            "warehouse/national-overview/total-sales-timeline",
            filterRequest
          )
          .then(response => {
            self.drawChart5(response, filterRequest);
          });
      }

      if (_mode === 0 || _mode === 6) {
        //debugger
        filterRequest.period2 = Object.assign({}, filter6.period);
        filterRequest.period3 = Object.assign({}, filter6.period2);

        //overwrite top level filter if exists
        !filter6.market ||
          (filterRequest.market = Object.assign({}, filter6.market));
        !filter6.distributor ||
          (filterRequest.distributor = Object.assign({}, filter6.distributor));

        this.charts.chart6.loading = true;

        this.$api.data
          .requestReportData(
            "warehouse/national-overview/total-sales-comparison",
            filterRequest
          )
          .then(response => {
            self.drawChart6(response, filterRequest);
          });
      }

      if (_mode === 0 || _mode === 7) {
        //debugger
        filterRequest.period2 = Object.assign({}, filter7.period);
        filterRequest.period3 = Object.assign({}, filter7.period2);

        //overwrite top level filter if exists
        !filter7.market ||
          (filterRequest.market = Object.assign({}, filter7.market));
        !filter7.distributor ||
          (filterRequest.distributor = Object.assign({}, filter7.distributor));

        this.charts.chart7.loading = true;

        this.$api.data
          .requestReportData(
            "warehouse/national-overview/distributors-sales",
            filterRequest
          )
          .then(response => {
            self.drawChart7(response, filterRequest);
          });
      }
    },
    getEmptyStates(response) {
      let allStates = [],
        dataStates = [];

      if (this.$refs.filteringPanel.selected.country) {
        allStates = [
          ...new Set(
            this.districts
              .filter(
                d =>
                  d.country === this.$refs.filteringPanel.selected.country.label
              )
              .map(f => f.market)
          )
        ];
        dataStates = [...new Set(response.map(i => i.market))];
        return allStates.filter(a => !dataStates.includes(a) && a !== "NONE");
      }

      return [];
    },
    drawChart1: function(response, filter) {
      //debugger
      this.charts.chart1.loading = false;
      //debugger
      if (response.length === 0) return;

      let headers = [
        [
          "State",
          "% change",
          {
            type: "string",
            role: "tooltip"
          }
        ]
      ];

      this.charts.chart1.rawData = response;
      //debugger
      let metric = undefined;

      if (this.selectedMetric().id === "sold") metric = "sales_delta_percent2";
      if (this.selectedMetric().id === "bottles")
        metric = "bottles_delta_percent2";
      if (this.selectedMetric().id === "alc_amount")
        metric = "alc_amount_delta_percent2";

      let data = [
        ...response.map(item => {
          const tooltip = `$ Sold: ${(
            parseFloat(item["sales_delta_percent2"]) * 100
          ).toFixed(2)}%\nBottles: ${(
            parseFloat(item["bottles_delta_percent2"]) * 100
          ).toFixed(2)}%\nAmount of alcohol: ${(
            parseFloat(item["alc_amount_delta_percent2"]) * 100
          ).toFixed(2)}%`;

          return [item.market, parseFloat(item[metric] || 0) * 100, tooltip];
          //          return [item.country, parseFloat(item[metric] || 0) * 100, tooltip]
        })
      ];

      let region = "US";
      charts.chart1.chartOptions.resolution = "provinces";

      if (filter.territory && filter.territory.label === "Export") {
        region = "world";
        charts.chart1.chartOptions.resolution = "countries";
      }
      if (filter.territory && filter.territory.label === "Asia") {
        region = "142";
        charts.chart1.chartOptions.resolution = "countries";
      }
      if (filter.territory && filter.territory.label === "Europe") {
        region = "150";
        charts.chart1.chartOptions.resolution = "countries";
      }
      if (filter.territory && filter.territory.label === "United States") {
        region = "US";
        charts.chart1.chartOptions.resolution = "provinces";
      }
      if (filter.territory && filter.territory.label === "Canada") {
        region = "CA";

        charts.chart1.chartOptions.resolution = "provinces";
      }
      if (filter.territory && filter.territory.label === "Japan") {
        region = "JP";

        charts.chart1.chartOptions.resolution = "provinces";
      }

      this.charts.chart1.chartOptions.region = region;
      this.charts.chart1.chartData = headers.concat(data);
    },
    drawChart2: function(response) {
      this.charts.chart2.loading = false;

      if (response.length === 0) return;

      let headers = [["State", "% change"]];

      this.charts.chart2.rawData = response;

      let metric = undefined;

      if (this.selectedMetric().id === "sold") metric = "sales_delta_percent2";
      if (this.selectedMetric().id === "bottles")
        metric = "bottles_delta_percent2";
      if (this.selectedMetric().id === "alc_amount")
        metric = "alc_amount_delta_percent2";

      let data = [
        ...response
          .filter(item => parseFloat(item[metric] || 0) !== 0)
          .map(item => {
            return [item.market, parseFloat(item[metric] || 0) * 100];
          })
      ];

      //sort by percent desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      this.charts.chart2.chartData = headers.concat(data);
    },

    drawChart5: function(response) {
      this.charts.chart5.loading = false;

      if (response.length === 0) return;

      let headers = [["Date", "Sales"]];

      let metric = undefined;
      if (this.selectedMetric().id === "sold") metric = "sales";
      if (this.selectedMetric().id === "bottles") metric = "bottles";
      if (this.selectedMetric().id === "alc_amount") metric = "alc_amount";

      this.charts.chart5.rawData = response;

      let data = [
        ...response.map(item => {
          return [new Date(item.yymmdd), parseFloat(item[metric] || 0)];
        })
      ];

      this.charts.chart5.chartData = headers.concat(data);
    },
    drawChart6: function(response) {
      this.charts.chart6.rawData = response;

      this.charts.chart6.loading = false;

      if (response.length === 0) return;

      this.updateChart6();
    },

    updateChart6() {
      let data = [];

      let metric = undefined;
      if (this.selectedMetric().id === "sold") metric = "sales";
      if (this.selectedMetric().id === "bottles") metric = "bottles";
      if (this.selectedMetric().id === "alc_amount") metric = "alc_amount";

      let filter = Object.assign({}, this.$refs.filteringPanel.selected);
      filter.period2 = Object.assign(
        {},
        this.$refs.filteringPanel6.selected.period
      );
      filter.period3 = Object.assign(
        {},
        this.$refs.filteringPanel6.selected.period2
      );

      let headers = [
        [
          "State",
          `${moment(filter.period.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period.endDate
          ).format("YYYY-MM-DD")}`,
          `${moment(filter.period2.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period2.endDate
          ).format("YYYY-MM-DD")}`,
          `${moment(filter.period3.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period3.endDate
          ).format("YYYY-MM-DD")}`
        ]
      ];

      let groupedData = [];

      if (!this.charts.chart6.groupByYearsView) {
        this.charts.chart6.rawData.forEach(t => {
          let mm = groupedData.find(g => g["x"] === parseInt(t.mm));

          if (!mm) {
            let o = {};

            o["x"] = parseInt(t.mm);

            o[`${metric}${t.period}`] = parseFloat(t[metric]);

            groupedData.push(o);
          } else {
            if (!mm[`${metric}${t.period}`]) mm[`${metric}${t.period}`] = 0;

            mm[`${metric}${t.period}`] += parseFloat(t[metric]);
          }
        });
        data = [
          ...groupedData.map(item => {
            return [
              parseInt(item.x),
              parseFloat(item[`${metric}1`] || 0),
              parseFloat(item[`${metric}2`] || 0),
              parseFloat(item[`${metric}3`] || 0)
            ];
          })
        ];
      } else {
        this.charts.chart6.rawData.forEach(t => {
          let yy = groupedData.find(g => g["x"] === parseInt(t.yy));

          if (!yy) {
            let o = {};

            o["x"] = parseInt(t.yy);

            o[`${metric}${t.period}`] = parseFloat(t[metric]);

            groupedData.push(o);
          } else {
            if (!yy[`${metric}${t.period}`]) yy[`${metric}${t.period}`] = 0;

            yy[`${metric}${t.period}`] += parseFloat(t[metric]);
          }
        });

        data = [
          ...groupedData.map(item => {
            return [
              parseInt(item.x),
              parseFloat(item[`${metric}1`] || 0),
              parseFloat(item[`${metric}2`] || 0),
              parseFloat(item[`${metric}3`] || 0)
            ];
          })
        ];
      }

      data = data.sort(function(a, b) {
        return a[0] - b[0];
      });

      this.charts.chart6.chartData = headers.concat(data);
    },
    drawChart7(response) {
      this.charts.chart7.rawData = response;

      this.charts.chart7.loading = false;

      if (response.length === 0) return;

      this.updateChart7();
    },
    updateChart7: function() {
      let data = [];
      let metric = undefined;
      if (this.selectedMetric().id === "sold") metric = "sales";
      if (this.selectedMetric().id === "bottles") metric = "bottles";
      if (this.selectedMetric().id === "alc_amount") metric = "alc_amount";

      let filter = Object.assign({}, this.$refs.filteringPanel.selected);
      filter.period2 = Object.assign(
        {},
        this.$refs.filteringPanel7.selected.period
      );
      filter.period3 = Object.assign(
        {},
        this.$refs.filteringPanel7.selected.period2
      );

      let headers = [
        [
          "State",
          `${moment(filter.period.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period.endDate
          ).format("YYYY-MM-DD")}`,
          `${moment(filter.period2.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period2.endDate
          ).format("YYYY-MM-DD")}`,
          `${moment(filter.period3.startDate).format("YYYY-MM-DD")} - ${moment(
            filter.period3.endDate
          ).format("YYYY-MM-DD")}`
        ]
      ];

      let groupedData = [];

      //pivot data
      this.charts.chart7.rawData.forEach(t => {
        let distributor = groupedData.find(
          g => g["distributor"] === t.distributor
        );

        if (!distributor) {
          let o = {};

          o["distributor"] = t.distributor;

          o[`${metric}${t.period}`] = parseFloat(t[metric]);

          groupedData.push(o);
        } else {
          if (!distributor[`${metric}${t.period}`])
            distributor[`${metric}${t.period}`] = 0;

          distributor[`${metric}${t.period}`] += parseFloat(t[metric]);
        }
      });

      data = [
        ...groupedData.map(item => {
          return [
            item.distributor,
            parseFloat(item[`${metric}1`] || 0),
            parseFloat(item[`${metric}2`] || 0),
            parseFloat(item[`${metric}3`] || 0)
          ];
        })
      ];

      //sort by sales desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      this.charts.chart7.pagination.total = data.length;
      data = data.slice(
        this.charts.chart7.pagination.value * 10 - 10,
        this.charts.chart7.pagination.value * 10
      );

      let avgMetricValue = this.$helpers.average(data.map(item => item[1]));

      if (this.charts.chart7.isBarChart) {
        this.charts.chart7.chartOptions.vAxis.viewWindow = {
          //max: avgMetricValue * 5,
          max: avgMetricValue,
          min: 0
        };

        this.charts.chart7.chartOptions.hAxis.viewWindow = {
          max: data.length,
          min: 0
        };
      } else {
        this.charts.chart7.chartOptions.hAxis.viewWindow = {
          max: avgMetricValue * 5,
          min: 0
        };

        this.charts.chart7.chartOptions.vAxis.viewWindow = {
          max: data.length,
          min: 0
        };
      }

      this.charts.chart7.chartData = headers.concat(data);

      //this.$forceUpdate()
    }
  }
};
</script>

<style scoped>
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css";

::v-deep .fab-main .material-icons.close {
  opacity: 1 !important;
}
</style>
