export default {
  chart1Table: {
    name: "custom_table_wh_national_overview_chart1",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Market", "$ Sold 1", "$ Sold 2", "$ Difference", "% Change"],
      totalColumns: [
        {
          name: "Count",
          alias: "Markets",
          method: "count",
          style: {
            style: "decimal"
          }
        },
        {
          name: "$ Sold 1",
          alias: "Sold 1",
          style: {
            style: "currency",
            currency: "USD"
          }
        },
        {
          name: "$ Sold 2",
          alias: "Sold 2",
          style: {
            style: "currency",
            currency: "USD"
          }
        },
        {
          name: "$ Difference",
          alias: "Difference",
          style: {
            style: "currency",
            currency: "USD"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Difference').value} (${(self.totals.find(t => t.alias === 'Difference').value*100/self.totals.find(t => t.alias === 'Sold 1').value).toFixed(2)} %)"
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold 1",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "$ Sold 2",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "$ Difference",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart1TableBottles: {
    name: "custom_table_wh_national_overview_chart1",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Market", "Bottles 1", "Bottles 2", "Difference", "% Change"],
      totalColumns: [
        {
          name: "Count",
          alias: "Markets",
          method: "count",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Bottles 1",
          alias: "Bottles 1",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Bottles 2",
          alias: "Bottles 2",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Difference",
          alias: "Difference",
          style: {
            style: "decimal"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Difference').value} (${(self.totals.find(t => t.alias === 'Difference').value*100/self.totals.find(t => t.alias === 'Bottles 1').value).toFixed(2)} %)"
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "Bottles 1",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Bottles 2",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Difference",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart1TableAlcAmount: {
    name: "custom_table_wh_national_overview_chart1",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Market", "Amount 1", "Amount 2", "Difference", "% Change"],
      totalColumns: [
        {
          name: "Count",
          alias: "Markets",
          method: "count",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Amount 1",
          alias: "Amount 1",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Amount 2",
          alias: "Amount 2",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Difference",
          alias: "Difference",
          style: {
            style: "decimal"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Difference').value} (${(self.totals.find(t => t.alias === 'Difference').value*100/self.totals.find(t => t.alias === 'Amount 1').value).toFixed(2)} %)"
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "Amount 1",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Amount 2",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Difference",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart2Table: {
    name: "custom_table_wh_national_overview_chart2",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Market", "$ Sold 1", "$ Sold 2", "$ Difference", "% Change"],
      totalColumns: [
        {
          name: "Count",
          alias: "Markets",
          method: "count",
          style: {
            style: "decimal"
          }
        },
        {
          name: "$ Sold 1",
          alias: "Spent 1",
          style: {
            style: "currency",
            currency: "USD"
          }
        },
        {
          name: "$ Sold 2",
          alias: "Spent 2",
          style: {
            style: "currency",
            currency: "USD"
          }
        },
        {
          name: "$ Difference",
          alias: "Change",
          style: {
            style: "currency",
            currency: "USD"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Change').value} (${(self.totals.find(t => t.alias === 'Change').value*100/self.totals.find(t => t.alias === 'Spent 1').value).toFixed(2)} %)"
        }
      ],

      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold 1",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "$ Sold 2",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "$ Difference",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart2TableBottles: {
    name: "custom_table_wh_national_overview_chart2",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Market", "Bottles 1", "Bottles 2", "Difference", "% Change"],
      totalColumns: [
        {
          name: "Count",
          alias: "Markets",
          method: "count",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Bottles 1",
          alias: "Bottles 1",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Bottles 2",
          alias: "Bottles 2",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Difference",
          alias: "Change",
          style: {
            style: "decimal"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Change').value} (${(self.totals.find(t => t.alias === 'Change').value*100/self.totals.find(t => t.alias === 'Bottles 1').value).toFixed(2)} %)"
        }
      ],

      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "Bottles 1",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Bottles 2",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Difference",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart2TableAlcAmount: {
    name: "custom_table_wh_national_overview_chart2",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Market", "Amount 1", "Amount 2", "Difference", "% Change"],
      totalColumns: [
        {
          name: "Count",
          alias: "Markets",
          method: "count",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Amount 1",
          alias: "Amount 1",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Amount 2",
          alias: "Amount 2",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Difference",
          alias: "Change",
          style: {
            style: "decimal"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Change').value} (${(self.totals.find(t => t.alias === 'Change').value*100/self.totals.find(t => t.alias === 'Amount 1').value).toFixed(2)} %)"
        }
      ],

      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "Amount 1",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Amount 2",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Difference",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart5Table: {
    name: "custom_table_wh_national_overview_chart5",
    groupByYearsView: false,
    YTD: false,
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Year", "Month", "$ Sold"],
      totalColumns: [
        {
          name: "$ Sold",
          alias: "Sold",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ],
      filterable: ["Year", "Month", "$ Sold"],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: "Year",
          type: "number"
        },
        {
          name: "Month",
          type: "month" //used for correct sorting
        },
        {
          name: "$ Sold",
          type: "number",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ]
    }
  },
  chart5TableBottles: {
    name: "custom_table_wh_national_overview_chart5",
    groupByYearsView: false,
    YTD: false,
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Year", "Month", "Bottles"],
      totalColumns: [
        {
          name: "Bottles",
          alias: "Bottles",
          style: {
            style: "decimal"
          }
        }
      ],
      filterable: ["Year", "Month", "Bottles"],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: "Year",
          type: "number"
        },
        {
          name: "Month",
          type: "month" //used for correct sorting
        },
        {
          name: "Bottles",
          type: "number",
          style: {
            style: "decimal"
          }
        }
      ]
    }
  },
  chart5TableAlcAmount: {
    name: "custom_table_wh_national_overview_chart5",
    groupByYearsView: false,
    YTD: false,
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Year", "Month", "Amount"],
      totalColumns: [
        {
          name: "Amount",
          alias: "Amount",
          style: {
            style: "decimal"
          }
        }
      ],
      filterable: ["Year", "Month", "Amount"],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: "Year",
          type: "number"
        },
        {
          name: "Month",
          type: "month" //used for correct sorting
        },
        {
          name: "Amount",
          type: "number",
          style: {
            style: "decimal"
          }
        }
      ]
    }
  }
};
