var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.away,
      expression: "away"
    }],
    staticClass: "fab-wrapper",
    style: [_vm.pos, {
      zIndex: _vm.zIndex
    }, {
      position: _vm.positionType
    }],
    attrs: {
      "id": _vm.position + '-wrapper'
    }
  }, [_c('div', {
    staticClass: "actions-container",
    style: _vm.listPos,
    attrs: {
      "id": _vm.position + '-action'
    }
  }, [_c('transition', {
    attrs: {
      "name": "fab-actions-appear",
      "enter-active-class": _vm.transitionEnter,
      "leave-active-class": _vm.transitionLeave
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.toggle,
      expression: "toggle"
    }],
    staticClass: "fab-list"
  }, [_vm._l(_vm.actions, function (action, index) {
    return [_c('transition', {
      key: index,
      attrs: {
        "enter-active-class": "animated quick zoomIn",
        "leave-active-class": "animated quick zoomOut"
      },
      on: {
        "after-enter": _vm.afterActionsTransitionEnter
      }
    }, [action.tooltip ? [_vm.toggle ? _c('li', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.left.blur",
        value: "".concat(action.tooltip),
        expression: "`${action.tooltip}`",
        modifiers: {
          "left": true,
          "blur": true
        }
      }],
      ref: "actions",
      refInFor: true,
      staticClass: "pointer",
      style: {
        'background-color': action.color || _vm.bgColor
      },
      attrs: {
        "id": "fab-tooltip-target-".concat(index)
      },
      on: {
        "click": function click($event) {
          return _vm.toParent(action.name);
        }
      }
    }, [_c('i', {
      class: [_vm.actionIconSize, 'material-icons']
    }, [_vm._v(_vm._s(action.icon))])]) : _vm._e()] : [_vm.toggle ? _c('li', {
      staticClass: "pointer",
      style: {
        'background-color': action.color || _vm.bgColor
      },
      on: {
        "click": function click($event) {
          return _vm.toParent(action.name);
        }
      }
    }, [_c('i', {
      class: [_vm.actionIconSize, 'material-icons']
    }, [_vm._v(_vm._s(action.icon))])]) : _vm._e()]], 2)];
  })], 2)])], 1), _vm.rippleShow ? [_vm.mainTooltip ? [_c('div', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple",
      value: _vm.rippleColor == 'light' ? 'rgba(255, 255, 255, 0.35)' : '',
      expression: "rippleColor == 'light' ? 'rgba(255, 255, 255, 0.35)' : ''"
    }, {
      name: "tooltip",
      rawName: "v-tooltip",
      value: {
        content: _vm.mainTooltip,
        placement: _vm.tooltipPosition,
        classes: 'fab-tooltip'
      },
      expression: "{\n          content: mainTooltip,\n          placement: tooltipPosition,\n          classes: 'fab-tooltip'\n        }"
    }],
    staticClass: "fab-main pointer",
    style: {
      'background-color': _vm.bgColor,
      padding: _vm.paddingAmount
    },
    on: {
      "click": function click($event) {
        _vm.toggle = !_vm.toggle;
      }
    }
  }, [_c('i', {
    class: [_vm.mainIconSize, {
      rotate: _vm.toggle && _vm.allowRotation
    }, 'material-icons main']
  }, [_vm._v(_vm._s(_vm.mainIcon))]), _c('i', {
    class: [_vm.mainIconSize, {
      rotate: _vm.toggle && _vm.allowRotation
    }, 'material-icons close']
  }, [_vm._v(_vm._s(_vm.mainIcon))])])] : [_c('div', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple",
      value: _vm.rippleColor == 'light' ? 'rgba(255, 255, 255, 0.35)' : '',
      expression: "rippleColor == 'light' ? 'rgba(255, 255, 255, 0.35)' : ''"
    }],
    staticClass: "fab-main pointer",
    style: {
      'background-color': _vm.bgColor,
      padding: _vm.paddingAmount
    },
    on: {
      "click": function click($event) {
        _vm.toggle = !_vm.toggle;
      }
    }
  }, [_c('i', {
    class: [_vm.mainIconSize, {
      rotate: _vm.toggle && _vm.allowRotation
    }, 'material-icons main']
  }, [_vm._v(_vm._s(_vm.mainIcon))]), _c('i', {
    class: [_vm.mainIconSize, {
      rotate: _vm.toggle && _vm.allowRotation
    }, 'material-icons close']
  }, [_vm._v(_vm._s(_vm.mainIcon))])])]] : [_vm.mainTooltip ? [_c('div', {
    staticClass: "fab-main pointer",
    style: {
      'background-color': _vm.bgColor,
      padding: _vm.paddingAmount
    },
    attrs: {
      "v-tooltip": {
        content: _vm.mainTooltip,
        placement: _vm.tooltipPosition,
        classes: 'fab-tooltip'
      }
    }
  }, [_c('i', {
    staticClass: "material-icons md-36 main",
    class: {
      rotate: _vm.toggle && _vm.allowRotation
    }
  }, [_vm._v(_vm._s(_vm.mainIcon))]), _c('i', {
    staticClass: "material-icons md-36 close",
    class: {
      rotate: _vm.toggle && _vm.allowRotation
    }
  }, [_vm._v(_vm._s(_vm.mainIcon))])])] : [_c('div', {
    staticClass: "fab-main pointer",
    style: {
      'background-color': _vm.bgColor,
      padding: _vm.paddingAmount
    }
  }, [_c('i', {
    staticClass: "material-icons md-36 main",
    class: {
      rotate: _vm.toggle && _vm.allowRotation
    }
  }, [_vm._v(_vm._s(_vm.mainIcon))]), _c('i', {
    staticClass: "material-icons md-36 close",
    class: {
      rotate: _vm.toggle && _vm.allowRotation
    }
  }, [_vm._v(_vm._s(_vm.mainIcon))])])]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }