var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('fab', {
    attrs: {
      "position": "bottom-right",
      "position-type": "fixed",
      "bg-color": "#2f353a",
      "icon-size": "small",
      "main-icon": "menu",
      "fixed-tooltip": true,
      "actions": _vm.fabActions
    },
    on: {
      "bookmark1": function bookmark1($event) {
        return _vm.scrollToBookmark('bookmark1');
      },
      "bookmark2": function bookmark2($event) {
        return _vm.scrollToBookmark('bookmark2');
      },
      "bookmark3": function bookmark3($event) {
        return _vm.scrollToBookmark('bookmark3');
      },
      "bookmark4": function bookmark4($event) {
        return _vm.scrollToBookmark('bookmark4');
      },
      "bookmark5": function bookmark5($event) {
        return _vm.scrollToBookmark('bookmark5');
      }
    }
  }), _c('b-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: true,
      expression: "true"
    }]
  }, [_c('h2', [_vm._v("National Overview - Warehouse")]), _c('br'), _c('h4', [_vm._v("Main Period")]), _c('br'), _c('span', [_vm._v("Select your main data. All charts compare to this data.")]), _c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "warehouse/national-overview-top-bar",
      "show-labels": true,
      "compact": true,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": function search($event) {
        return _vm.getData(0);
      },
      "reset": _vm.onFilteringPanelReset
    }
  })], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', {
    ref: "bookmark1",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart1-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplate)
    }
  })]), _c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "id": "chart1",
      "default-mode": _vm.charts.chart1.isDefaultMode,
      "title": "Sales Development (+/-) CHART 1",
      "subtitle": _vm.charts.chart1.subtitle,
      "loading": _vm.charts.chart1.loading,
      "type": "GeoChart",
      "settings": {
        packages: ['geochart'],
        mapsApiKey: 'AIzaSyDPm_pLRVSsqrEqye4f4zJD0ZR-cE9Cbdk'
      },
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    },
    on: {
      "chart-element-selected": function chartElementSelected($event) {
        return _vm.stateChartClick($event, 'chart1');
      }
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart1-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filtering-panel', {
          ref: "filteringPanel1",
          attrs: {
            "mode": "server",
            "dataset-name": "warehouse/national-overview-chart-1",
            "show-labels": true,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel1.filters,
            "loaded": _vm.filteringPanel1.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(1);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1.isDefaultMode,
            expression: "charts.chart1.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isDefaultMode = !_vm.charts.chart1.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart1.isDefaultMode,
            expression: "!charts.chart1.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isDefaultMode = !_vm.charts.chart1.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart1Table.name,
            "loading": _vm.chart1Table.isLoading,
            "data": _vm.chart1Table.dataSet,
            "options": _vm.chart1Table.options
          },
          on: {
            "mounted": _vm.onChart1TableMount
          }
        })];
      },
      proxy: true
    }])
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _vm.charts.chart1.emptyStates.length > 0 ? _c('b-row', [_c('b-col', [_c('p'), _c('h5', [_vm._v("States without data for time period:")]), _vm._v(" " + _vm._s(_vm.charts.chart1.emptyStates.join(", ")) + " ")])], 1) : _vm._e()], 1), _c('b-col', {
    ref: "bookmark2",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart2-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplate)
    }
  })]), _c('g-chart-custom', {
    ref: "chart2",
    attrs: {
      "default-mode": _vm.charts.chart2.isDefaultMode,
      "title": "Sales Development (+/-) CHART 2",
      "subtitle": _vm.charts.chart2.subtitle,
      "type": _vm.$isMobile ? 'ColumnChart' : 'BarChart',
      "loading": _vm.charts.chart2.loading,
      "data": _vm.charts.chart2.chartData,
      "options": _vm.charts.chart2.chartOptions
    },
    on: {
      "chart-element-selected": function chartElementSelected($event) {
        return _vm.stateChartClick($event, 'chart2');
      }
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart2-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filtering-panel', {
          ref: "filteringPanel2",
          attrs: {
            "mode": "server",
            "dataset-name": "warehouse/national-overview-chart-2",
            "show-labels": true,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel2.filters,
            "loaded": _vm.filteringPanel2.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(2);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2.isDefaultMode,
            expression: "charts.chart2.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isDefaultMode = !_vm.charts.chart2.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart2.isDefaultMode,
            expression: "!charts.chart2.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isDefaultMode = !_vm.charts.chart2.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart2Table.name,
            "loading": _vm.chart2Table.isLoading,
            "data": _vm.chart2Table.dataSet,
            "options": _vm.chart2Table.options
          },
          on: {
            "mounted": _vm.onChart2TableMount
          }
        })];
      },
      proxy: true
    }])
  }), _vm.charts.chart2.emptyStates.length > 0 ? _c('b-row', [_c('b-col', [_c('p'), _c('h5', [_vm._v("States without data for time period:")]), _vm._v(" " + _vm._s(_vm.charts.chart2.emptyStates.join(", ")) + " ")])], 1) : _vm._e()], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', {
    ref: "bookmark3",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    ref: "chart5container"
  }, [_c('g-chart-custom', {
    ref: "chart5",
    attrs: {
      "id": "chart5",
      "title": "Total sales (".concat(_vm.selectedMetric().label, ")"),
      "subtitle": "Comparison of sales by periods",
      "show-tabs": true,
      "default-mode": _vm.charts.chart5.isDefaultMode,
      "loading": _vm.charts.chart5.loading,
      "type": "AnnotationChart",
      "settings": {
        packages: ['annotationchart']
      },
      "data": _vm.charts.chart5.chartData,
      "options": _vm.charts.chart5.chartOptions
    },
    on: {
      "ready": _vm.onTotalSalesReady
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart5-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filtering-panel', {
          ref: "filteringPanel5",
          attrs: {
            "mode": "server",
            "dataset-name": "warehouse/national-overview-chart-5",
            "show-labels": true,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel5.filters,
            "loaded": _vm.filteringPanel5.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(5);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }]
        }, [_c('b-button-group', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }],
          staticClass: "mb-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(3);
            }
          }
        }, [_vm._v(" 3 months ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(6);
            }
          }
        }, [_vm._v(" 6 months ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(12);
            }
          }
        }, [_vm._v(" 1 year ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(24);
            }
          }
        }, [_vm._v(" 2 years ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart5ZoomClick(60);
            }
          }
        }, [_vm._v(" 5 years ")])], 1)], 1), _c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart5.isDefaultMode,
            expression: "!charts.chart5.isDefaultMode"
          }]
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "checkboxGroupByYears",
            "inline": "",
            "switch": ""
          },
          on: {
            "input": _vm.updateChart5Table
          },
          model: {
            value: _vm.chart5Table.groupByYearsView,
            callback: function callback($$v) {
              _vm.$set(_vm.chart5Table, "groupByYearsView", $$v);
            },
            expression: "chart5Table.groupByYearsView"
          }
        }, [_vm._v(" Group by years ")]), _c('b-form-checkbox', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.chart5Table.groupByYearsView,
            expression: "chart5Table.groupByYearsView"
          }],
          attrs: {
            "name": "checkboxYTD",
            "inline": ""
          },
          on: {
            "input": _vm.updateChart5Table
          },
          model: {
            value: _vm.chart5Table.YTD,
            callback: function callback($$v) {
              _vm.$set(_vm.chart5Table, "YTD", $$v);
            },
            expression: "chart5Table.YTD"
          }
        }, [_vm._v(" Limit to YTD ")])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isDefaultMode = !_vm.charts.chart5.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart5.isDefaultMode,
            expression: "!charts.chart5.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isDefaultMode = !_vm.charts.chart5.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart5Table.name,
            "loading": _vm.chart5Table.isLoading,
            "data": _vm.chart5Table.dataSet,
            "options": _vm.chart5Table.options
          },
          on: {
            "mounted": _vm.onChart5TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1)])], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', {
    ref: "bookmark4",
    attrs: {
      "lg": "12"
    }
  }, [_c('g-chart-custom', {
    ref: "chart6",
    attrs: {
      "title": "Total sales comparison (".concat(_vm.selectedMetric().label, ")"),
      "subtitle": "Comparison of sales by periods",
      "default-mode": _vm.charts.chart6.isDefaultMode,
      "type": _vm.charts.chart6.type,
      "loading": _vm.charts.chart6.loading,
      "data": _vm.charts.chart6.chartData,
      "options": _vm.charts.chart6.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart6-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('filtering-panel', {
          ref: "filteringPanel6",
          attrs: {
            "mode": "server",
            "dataset-name": "national-overview-chart-6",
            "show-labels": true,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel6.filters,
            "loaded": _vm.filteringPanel6.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(6);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-form-checkbox', {
          attrs: {
            "name": "checkboxGroupByYears2",
            "inline": "",
            "switch": ""
          },
          on: {
            "input": _vm.updateChart6
          },
          model: {
            value: _vm.charts.chart6.groupByYearsView,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart6, "groupByYearsView", $$v);
            },
            expression: "charts.chart6.groupByYearsView"
          }
        }, [_vm._v(" Group by years ")])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart6.isColumnType,
            expression: "charts.chart6.isColumnType"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6.isColumnType = !_vm.charts.chart6.isColumnType;
              _vm.charts.chart6.type = 'LineChart';
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-line"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart6.isColumnType,
            expression: "!charts.chart6.isColumnType"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart6.isColumnType = !_vm.charts.chart6.isColumnType;
              _vm.charts.chart6.type = 'ColumnChart';
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', {
    ref: "bookmark5"
  }, [_c('b-popover', {
    attrs: {
      "target": "chart7-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplateAllMetrics)
    }
  })]), _c('g-chart-custom', {
    ref: "chart7",
    attrs: {
      "title": "Distributors sales (".concat(_vm.selectedMetric().label, ")"),
      "subtitle": "Comparison of sales by periods",
      "type": _vm.charts.chart7.type,
      "loading": _vm.charts.chart7.loading,
      "data": _vm.charts.chart7.chartData,
      "options": _vm.charts.chart7.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart7-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('filtering-panel', {
          ref: "filteringPanel7",
          attrs: {
            "mode": "server",
            "dataset-name": "warehouse/national-overview-chart-7",
            "show-labels": true,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel7.filters,
            "loaded": _vm.filteringPanel7.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(7);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          attrs: {
            "lg": "2",
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart7.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart7
          },
          model: {
            value: _vm.charts.chart7.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart7.pagination, "value", $$v);
            },
            expression: "charts.chart7.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart7.isBarChart,
            expression: "charts.chart7.isBarChart"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart7.isBarChart = !_vm.charts.chart7.isBarChart;
              _vm.charts.chart7.type = 'BarChart';
              _vm.updateChart7();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-line"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart7.isBarChart,
            expression: "!charts.chart7.isBarChart"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart7.isBarChart = !_vm.charts.chart7.isBarChart;
              _vm.charts.chart7.type = 'ColumnChart';
              _vm.updateChart7();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('market-modal', {
    ref: "marketModal"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }